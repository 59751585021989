import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import RightSide from '../../components/rightside-general'

import BG from '../../images/awards-bg.jpg'

class PropertyTaxToolsApp extends React.Component {
	render() {
		const siteTitle = 'Property Tax Tools App'
		const siteDescription = this.props.data.site.siteMetadata.description

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />
				<div
					id="sliderhead"
					style={{
						background: `url(${BG}) 50% 13px fixed rgb(34, 34, 34`,
						position: 'relative',
					}}
					className="blog-header2"
				/>
				<div className="container">
					<div className="row">
						<div className="col-md-8 left-list">
							<div id="contentgen">
								<h3>WSC GROUP PROPERTY TAX TOOLS APP NOW AVAILABLE</h3>
								<br />
								<p>
									{' '}
									WSC Group are pleased to announce the release of our FREE app
									available for both iOS and Android users.
								</p>
								<p>
									The app contains helpful calculations, tools and resources to
									assist you. There is also a Logbook built into the app which
									you can use to track your car mileage during the year and then
									send to us at tax time.
								</p>
								<p>
									Keep all your tax resources in one handy location on your
									mobile device!
								</p>
								<div class="row qrcodediv">
									<div class="col-md-4 col-sm-4 qrcodedet">
										<img
											src="https://d33wubrfki0l68.cloudfront.net/249274e4cb11019f2d7c22203fb9a6113e1832ec/03679/img/wsc/wsc_group_-_qr_code.png"
											alt="QR Code"
										/>
									</div>
									<div class="col-md-8 col-sm-8 qrcodedetright">
										<h3 className="mt-5">WSC Group Property Tax Tools</h3>
										<p>
											Download the app now using the left QR Code or search "WSC
											Group Property Tax Tools" in the App Store or Google Play.
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-4 right-list pull-right">
							<RightSide />
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default PropertyTaxToolsApp

export const PropertyTaxToolsAppPageQuery = graphql`
	query PropertyTaxToolsAppPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
